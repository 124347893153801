import { AppId, DevelopEnvironment, Environment, XPlatform } from '@foxeet/domain';
import { VERSION } from './version';

export const environment: Environment = {
  app: AppId.IVFORCE,
  appName: 'Tasafy Valum',
  wikiURL: 'https://wiki-force.valumre.com/',
  wikiURLBancoEsp: 'https://wiki-tecnica.iv.solutions',
  platform: XPlatform.MOBILE,
  production: false,

  // defaultEndpoint: 'https://192.168.16.10:5001', // Javi
  // defaultEndpoint: 'https://192.168.16.25:5001', // Gilbert
  // defaultEndpoint: 'https://192.168.16.24:5001', // Alfredo
  // defaultEndpoint: 'https://192.168.16.50:5001', // Alberto
  // defaultEndpoint: 'https://192.168.16.37:5001', // David

  // defaultEndpoint: 'https://dev-2-api.iv.solutions',

  appVersion: VERSION,
  version: VERSION,
  envName: DevelopEnvironment.dev,
  defaultEndpoint: 'https://dev-migration.iv.solutions',
  devEndpoint: 'https://dev-migration.iv.solutions',
  qaEndpoint: 'https://qa-migration.iv.solutions',
  prodEndpoint: 'https://api.iv.solutions',

  mapbox: {
    accessToken: 'pk.eyJ1IjoiaW5zdGl0dXRvIiwiYSI6ImNrN2xwd3hxdDAyYTkzZXAzdXJvaDd5aTQifQ.ep-LJ8wr0Jm3_nWlWf7NUw',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
